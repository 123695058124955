<route>
{
  "meta": {
    "auth": "accountManageAdd"
  }
}
</route>

<template>
  <div>
    <el-card v-if="auditDetail && auditDetail.length" class="auditDetailContainer">
      <div v-for="(i, n) in auditDetail" :key="n">{{ i.rejectReason }}</div>
    </el-card>
    <el-card class="accountContainer">
      <el-form ref="form" class="vendorFrom" :rules="rules" label-position="right" :model="form" label-width="13em">
        <el-form-item label="账号名称" prop="accountName">
          <el-input :disabled="isLockBasicInfo" v-model.trim="form.accountName"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model.trim="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="主体类型">
          <el-radio-group
            @change="handleOrganizationTypeChange"
            :disabled="isLockBasicInfo"
            v-model="form.organizationType"
          >
            <el-radio label="2">企业</el-radio>
            <el-radio label="4">个体工商户</el-radio>
            2401：小微商户，指无营业执照的商户。
            <el-radio label="2401">小微商户</el-radio>
            <el-radio label="2500">个人</el-radio>
          </el-radio-group>
        </el-form-item>

        <template v-if="['2', '4'].includes(form.organizationType)">
          <div class="secondTitle title">营业执照/登记证书信息</div>
          <el-form-item label="证件扫描件" prop="businessLicenseInfo.businessLicenseCopy">
            <el-upload
              :disabled="isLockBasicInfo"
              class="avatar-uploader"
              accept="image/jpeg,image/jpg,image/png"
              action=""
              :show-file-list="false"
              :auto-upload="false"
              :on-change="handleBusinessLicenseCopy"
            >
              <img v-if="form.pics.businessLicenseCopy" :src="form.pics.businessLicenseCopy" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span class="assist">上传营业执照/登记证书</span>
          </el-form-item>
          <el-form-item label="证件注册号" prop="businessLicenseInfo.businessLicenseNumber">
            <el-input :disabled="isLockBasicInfo" v-model="form.businessLicenseInfo.businessLicenseNumber"></el-input>
          </el-form-item>
          <el-form-item label="商家名称" prop="businessLicenseInfo.merchantName">
            <el-input :disabled="isLockBasicInfo" v-model="form.businessLicenseInfo.merchantName"></el-input>
          </el-form-item>
          <el-form-item label="经营者/法定代表人姓名" prop="businessLicenseInfo.legalPerson">
            <el-input :disabled="isLockBasicInfo" v-model="form.businessLicenseInfo.legalPerson"></el-input>
          </el-form-item>
          <el-form-item label="营业期限">
            <el-date-picker
              :disabled="isForeverBusinessTime || isLockBasicInfo"
              v-model="form.businessLicenseInfo.businessTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            <el-checkbox :disabled="isLockBasicInfo" style="margin-left: 10px" v-model="isForeverBusinessTime"
              >长期</el-checkbox
            >
          </el-form-item>
        </template>

        <div class="secondTitle title">补充说明信息</div>
        <el-form-item label="补充说明" prop="businessAdditionDesc">
          <el-input
            type="textarea"
            style="width: 400px"
            :rows="6"
            maxlength="512"
            show-word-limit
            :disabled="isLockBasicInfo"
            v-model="form.businessAdditionDesc"
          ></el-input>
        </el-form-item>
        <el-form-item label="补充说明图片">
          <el-upload
            :disabled="isLockBasicInfo"
            class="imageListUploader"
            accept="image/jpeg,image/jpg,image/png"
            action=""
            list-type="picture-card"
            :file-list="form.pics.businessAdditionPicList"
            :limit="5"
            :auto-upload="false"
            :on-change="handleBusinessAdditionPic"
            :on-remove="handleBusinessAdditionPicRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <span class="assist">上传补充说明图片</span>
        </el-form-item>

        <template v-if="['2', '4'].includes(form.organizationType)">
          <div class="secondTitle title">特殊资质</div>
          <el-form-item label="特殊资质说明图片">
            <el-upload
              :disabled="isLockBasicInfo"
              class="imageListUploader"
              accept="image/jpeg,image/jpg,image/png"
              action=""
              list-type="picture-card"
              :file-list="form.pics.qualificationList"
              :limit="5"
              :auto-upload="false"
              :on-change="handleQualificationsPic"
              :on-remove="handleQualificationsPicRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <span class="assist">上传特殊资质说明图片</span>
          </el-form-item>
        </template>

        <div class="secondTitle title">经营者或法人证件</div>
        <el-form-item label="证件类型" prop="idDocType">
          <el-select :disabled="isLockBasicInfo" v-model="form.idDocType">
            <el-option label="中国大陆居民-身份证" value="IDENTIFICATION_TYPE_MAINLAND_IDCARD"></el-option>
            <el-option
              :disabled="!isAllowPassport"
              label="其他国家或地区居民-护照"
              value="IDENTIFICATION_TYPE_OVERSEA_PASSPORT"
            ></el-option>
            <el-option
              :disabled="!isAllowPassport"
              label="中国香港居民–来往内地通行证"
              value="IDENTIFICATION_TYPE_HONGKONG"
            ></el-option>
            <el-option
              :disabled="!isAllowPassport"
              label="中国澳门居民–来往内地通行证"
              value="IDENTIFICATION_TYPE_MACAO"
            ></el-option>
            <el-option
              :disabled="!isAllowPassport"
              label="中国台湾居民–来往大陆通行证"
              value="IDENTIFICATION_TYPE_TAIWAN"
            ></el-option>
          </el-select>
        </el-form-item>

        <template v-if="form.idDocType === 'IDENTIFICATION_TYPE_MAINLAND_IDCARD'">
          <el-form-item label="身份证证件照片">
            <div class="idImage">
              <el-upload
                :disabled="isLockBasicInfo"
                class="avatar-uploader"
                action=""
                accept="image/jpeg,image/jpg,image/png"
                :show-file-list="false"
                :auto-upload="false"
                :on-change="handleIdCardCopy"
              >
                <img v-if="form.pics.idCardCopy" :src="form.pics.idCardCopy" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>身份证人像面</span>
            </div>
            <div class="idImage">
              <el-upload
                :disabled="isLockBasicInfo"
                class="avatar-uploader"
                accept="image/jpeg,image/jpg,image/png"
                action=""
                :show-file-list="false"
                :auto-upload="false"
                :on-change="handleIdCardNational"
              >
                <img v-if="form.pics.idCardNational" :src="form.pics.idCardNational" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>身份证国徽面</span>
            </div>
          </el-form-item>
          <el-form-item label="身份证姓名" prop="idCardInfo.idCardName">
            <el-input :disabled="isLockBasicInfo" v-model.trim="form.idCardInfo.idCardName"></el-input>
          </el-form-item>
          <el-form-item label="身份证证件号" prop="idCardInfo.idCardNumber">
            <el-input
              :disabled="isLockBasicInfo"
              v-model="form.idCardInfo.idCardNumber"
              maxlength="18"
              type="text"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证有效期" prop="idCardInfo.idCardValidTime">
            <!-- <el-input :disabled="isLockBasicInfo" v-model.trim="form.idCardInfo.idCardValidTime"></el-input>
            <span class="assist">示例值：2026-06-06，长期</span> -->
            <el-date-picker
              :disabled="isForeverIdCardTime || isLockBasicInfo"
              v-model="form.idCardInfo.idCardValidTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
            <el-checkbox :disabled="isLockBasicInfo" style="margin-left: 10px" v-model="isForeverIdCardTime"
              >长期</el-checkbox
            >
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="证件照片">
            <div class="idImage">
              <el-upload
                :disabled="isLockBasicInfo"
                class="avatar-uploader"
                action=""
                accept="image/jpeg,image/jpg,image/png"
                :show-file-list="false"
                :auto-upload="false"
                :on-change="handleIdDocCopy"
              >
                <img v-if="form.pics.idDocCopy" :src="form.pics.idDocCopy" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="证件姓名" prop="idDocInfo.idDocName">
            <el-input :disabled="isLockBasicInfo" v-model.trim="form.idDocInfo.idDocName"></el-input>
          </el-form-item>
          <el-form-item label="证件号" prop="idDocInfo.idDocNumber">
            <el-input :disabled="isLockBasicInfo" v-model="form.idDocInfo.idDocNumber" type="text"></el-input>
          </el-form-item>
          <el-form-item label="证件有效期">
            <el-input :disabled="isLockBasicInfo" v-model.trim="form.idDocInfo.docPeriodEnd"></el-input>
            <span class="assist">示例值：2026-06-06，长期</span>
          </el-form-item>
        </template>

        <div class="secondTitle title">超级管理员</div>
        <el-form-item label="管理员类型">
          <el-radio-group
            :disabled="isLockBasicInfo || form.organizationType == '2500' || form.organizationType == '2401'"
            v-model="form.contactInfo.contactType"
          >
            <el-radio label="66">负责人</el-radio>
            <el-radio label="65">经营者/法人</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.contactInfo.contactType == 65">
          <el-form-item label="姓名">
            <el-input disabled :value="contactInfo.name"></el-input>
          </el-form-item>
          <el-form-item label="身份证件号码">
            <el-input disabled :value="contactInfo.number" maxlength="18" type="text" show-word-limit> </el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="姓名" prop="contactInfo.contactName">
            <el-input :disabled="isLockBasicInfo" v-model.trim="form.contactInfo.contactName"></el-input>
          </el-form-item>
          <el-form-item label="身份证件号码" prop="contactInfo.contactIdCardNumber">
            <el-input :disabled="isLockBasicInfo" v-model.trim="form.contactInfo.contactIdCardNumber"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="手机号" prop="contactInfo.mobilePhone">
          <el-input
            oninput="value=value.replace(/[^0-9-]/g,'')"
            :disabled="isLockBasicInfo"
            v-model="form.contactInfo.mobilePhone"
            maxlength="11"
            type="text"
            show-word-limit
          ></el-input>
        </el-form-item>
        <!-- <el-form-item v-if="form.wxOpenId" label="微信">
          <div class="wxInfoContainer">
            <img :src="wxInfo.avatarUrl" class="avatar" />
            <span> {{ wxInfo.nickName }} </span>
          </div>
        </el-form-item> -->
        <el-form-item label="邮箱" prop="contactInfo.contactEmail">
          <el-input :disabled="isLockBasicInfo" v-model.trim="form.contactInfo.contactEmail"></el-input>
        </el-form-item>

        <div class="secondTitle title">结算银行账户</div>

        <el-form-item label="是否填写银行账户">
          <el-radio-group v-model="form.needAccountInfo">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <template v-if="form.needAccountInfo">
          <el-form-item label="账号类型" v-if="form.needAccountInfo">
            <el-radio-group v-model="form.accountInfo.bankAccountType">
              <el-radio :disabled="disabledBankType" label="74">对公银行账户</el-radio>
              <el-radio :disabled="disabledBankType" label="75">经营者个人银行卡</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="开户银行">
            <el-select class="searchInput" v-model="form.accountInfo.accountBank">
              <el-option v-for="(i, n) in bankList" :key="n" :label="i" :value="i"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="银行全称" v-if="form.accountInfo.accountBank === '其他银行'">
            <el-input v-model="form.accountInfo.bankName"></el-input>
            <span class="assist">开户银行不是18家直连银行时需填写银行全称，如: 深圳农村商业银行XXX支行</span>
          </el-form-item>
          <el-form-item label="开户名" v-if="form.accountInfo.bankAccountType === '74'">
            <el-input v-model="form.accountInfo.accountName"></el-input>
          </el-form-item>
          <el-form-item label="开户行所在地">
            <el-cascader
              v-model="bankAddressCode"
              :options="cityCodeList"
              @change="onCityCodeChange"
              :props="{
                label: 'districtName',
                value: 'id',
                children: 'child'
              }"
            ></el-cascader>
            <span style="margin-left: 10px">{{ form.accountInfo.bankAddressCode }}</span>
          </el-form-item>

          <el-form-item label="银行账号">
            <el-input v-model.trim="form.accountInfo.accountNumber"></el-input>
          </el-form-item>
        </template>

        <el-form-item>
          <el-button type="primary" @click="handleSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabledBankType: true,
      bankAddressCode: [],
      auditDetail: [],
      originCityCodeList: [],
      bankList: [
        '工商银行',
        '中国银行',
        '交通银行',
        '建设银行',
        '农业银行',
        '浦发银行',
        '广发银行',
        '邮政储蓄银行',
        '招商银行',
        '光大银行',
        '平安银行',
        '中信银行',
        '兴业银行',
        '民生银行',
        '北京银行',
        '宁波银行',
        '华夏银行',
        '其他银行'
      ],
      // wxInfo: {
      //   avatarUrl: '',
      //   nickName: ''
      // },
      isLockBasicInfo: false,
      orderTime: [],
      idLimitTime: [],
      rules: [],
      outRequestNo: '',
      form: {
        remark: '',
        type: 6,
        wxOpenId: '',
        accountInfo: {
          accountBank: '',
          accountName: '',
          accountNumber: '',
          bankAccountType: '75',
          bankAddressCode: '',
          bankBranchId: '',
          bankName: '',
          cityCode: ''
        },
        accountName: '',
        businessId: 0,
        businessAdditionDesc: '',
        businessAdditionPics: '',
        qualifications: '',
        businessLicenseInfo: {
          businessLicenseCopy: '',
          businessLicenseNumber: '',
          businessTime: new Date(Date.now() + 8.64e7).toISOString().substr(0, 10),
          companyAddress: '',
          legalPerson: '',
          merchantName: ''
        },
        contactInfo: {
          contactEmail: '',
          contactIdCardNumber: '',
          contactName: '',
          contactType: '65',
          mobilePhone: ''
        },
        idCardInfo: {
          idCardCopy: '',
          idCardName: '',
          idCardNational: '',
          idCardNumber: '',
          idCardValidTime: ''
        },
        idDocInfo: {
          docPeriodEnd: '',
          idDocCopy: '',
          idDocName: '',
          idDocNumber: ''
        },
        idDocType: 'IDENTIFICATION_TYPE_MAINLAND_IDCARD',
        merchantShortname: '',
        needAccountInfo: false,
        organizationCertInfo: {
          organizationCopy: '',
          organizationNumber: '',
          organizationTime: ''
        },
        organizationType: '2401',
        pics: {
          businessAdditionPicList: [],
          businessAdditionPicIdList: [],
          businessAdditionPics: '',
          businessLicenseCopy: '',
          idCardCopy: '',
          idCardNational: '',
          idDocCopy: '',
          organizationCopy: '',
          qualifications: '',
          qualificationList: [],
          qualificationIdList: [],
          storeQrCode: ''
        },
        salesSceneInfo: {
          miniProgramSubAppid: '',
          storeName: '',
          storeQrCode: '',
          storeUrl: ''
        }
      }
    }
  },
  computed: {
    isForeverIdCardTime: {
      get() {
        return this.form.idCardInfo.idCardValidTime === '长期'
      },
      set(val) {
        if (val) {
          this.form.idCardInfo.idCardValidTime = '长期'
        } else {
          this.form.idCardInfo.idCardValidTime = new Date(Date.now() + 8.64e7).toISOString().substr(0, 10)
        }
      }
    },
    isForeverBusinessTime: {
      get() {
        return this.form.businessLicenseInfo.businessTime === '长期'
      },
      set(val) {
        if (val) {
          this.form.businessLicenseInfo.businessTime = '长期'
        } else {
          this.form.businessLicenseInfo.businessTime = new Date(Date.now() + 8.64e7).toISOString().substr(0, 10)
        }
      }
    },
    cityCodeList() {
      return this.originCityCodeList.map(i => {
        if (!i.child || !i.child.length) {
          delete i.child
          return i
        }
        return {
          ...i,
          child: i.child.map(j => {
            if (!j.child || !j.child.length) {
              delete j.child
              return j
            }
            delete j.child
            return j
          })
        }
      })
    },
    contactInfo() {
      if (this.form.idDocType === 'IDENTIFICATION_TYPE_MAINLAND_IDCARD') {
        return {
          name: this.form.idCardInfo.idCardName,
          number: this.form.idCardInfo.idCardNumber
        }
      } else {
        return {
          name: this.form.idDocInfo.idDocName,
          number: this.form.idDocInfo.idDocNumber
        }
      }
    },
    isAllowPassport() {
      return ['2', '4'].includes(this.form.organizationType)
    }
  },
  watch: {
    'form.organizationType'(val) {
      if (val == 2500) {
        this.form.businessAdditionDesc = '该商户已持续从事电子商务经营活动满6个月，且期间经营收入累计超过20万元。'
        this.form.contactInfo.contactType = '65'
      } else {
        this.form.businessAdditionDesc = ''
      }
    }
  },
  methods: {
    onCityCodeChange(val) {
      console.log(val)
      if (val && val.length) {
        this.form.accountInfo.bankAddressCode = val[val.length - 1]
      }
    },
    // onPhoneChange(val) {
    //   this.form.wxOpenId = ''
    //   if (val.length === 11) {
    //     this.$http
    //       .get(`/boom-center-user-service/sysAdmin/members/getMemberInfoByPurePhoneNumber/${val}`)
    //       .then(res => {
    //         this.wxInfo.avatarUrl = res.avatarUrl
    //         this.wxInfo.nickName = res.wxNickname
    //         this.form.wxOpenId = res.wxOpenId
    //       })
    //       .catch(err => {
    //         this.$message.error('该号码不存在，请先登录小程序')
    //       })
    //   }
    // },
    setRules() {
      this.rules = this.$initRules([
        {
          label: '账号昵称',
          value: 'accountName',
          type: 'input',
          required: true
        },

        {
          label: '证件扫描件',
          value: 'businessLicenseCopy',
          type: 'upload',
          required: true,
          parent: 'businessLicenseInfo'
        },
        {
          label: '证件类型',
          value: 'idDocType',
          type: 'select',
          required: true
        },

        {
          label: '证件注册号',
          value: 'businessLicenseNumber',
          type: 'input',
          required: true,
          parent: 'businessLicenseInfo'
        },
        {
          label: '商家名称',
          value: 'merchantName',
          type: 'input',
          required: true,
          parent: 'businessLicenseInfo'
        },
        {
          label: '经营者/法定代表人姓名',
          value: 'legalPerson',
          type: 'input',
          required: true,
          parent: 'businessLicenseInfo'
        },
        {
          label: '营业期限',
          value: 'businessTime',
          type: 'select',
          required: true,
          parent: 'businessLicenseInfo'
        },
        {
          label: '身份证正面',
          value: 'idCardCopy',
          type: 'upload',
          required: true,
          parent: 'idCardInfo'
        },
        {
          label: '身份证反面',
          value: 'idCardNational',
          type: 'upload',
          required: true,
          parent: 'idCardInfo'
        },
        {
          label: '身份证姓名',
          value: 'idCardName',
          type: 'input',
          required: true,
          parent: 'idCardInfo'
        },
        {
          label: '身份证证件号',
          value: 'idCardNumber',
          type: 'input',
          required: true,
          parent: 'idCardInfo'
        },
        {
          label: '证件姓名',
          value: 'idDocName',
          type: 'input',
          required: true,
          parent: 'idDocInfo'
        },
        {
          label: '证件号',
          value: 'idDocNumber',
          type: 'input',
          required: true,
          parent: 'idDocInfo'
        },
        {
          label: '身份证有效期',
          value: 'idCardValidTime',
          type: 'input',
          required: true,
          parent: 'idCardInfo'
        },
        {
          label: '管理员姓名',
          value: 'contactName',
          type: 'input',
          required: true,
          parent: 'contactInfo'
        },
        {
          label: '管理员身份证件号码',
          value: 'contactIdCardNumber',
          type: 'input',
          required: true,
          parent: 'contactInfo'
        },
        {
          label: '管理员手机号',
          value: 'mobilePhone',
          type: 'input',
          required: true,
          parent: 'contactInfo'
        },
        {
          label: '管理员邮箱',
          value: 'contactEmail',
          type: 'input',
          required: true,
          parent: 'contactInfo'
        },
        {
          label: '管理员姓名',
          value: 'contactName',
          type: 'input',
          required: true,
          parent: 'contactInfo'
        }
      ])
    },
    handleOrganizationTypeChange(val) {
      if (val == 2) {
        this.disabledBankType = true
        this.form.accountInfo.bankAccountType = '74'
      } else if (val == 4) {
        this.disabledBankType = false
        this.form.accountInfo.bankAccountType = '75'
      } else {
        this.disabledBankType = true
        this.form.accountInfo.bankAccountType = '75'
      }
    },
    handleUploadWxFile(file, type) {
      const loading = this.$loading()
      this.$store
        .dispatch('upload/uploadWxFile', file)
        .then(res => {
          if (type === 'businessLicenseCopy') {
            this.form.businessLicenseInfo.businessLicenseCopy = res.wxCode
            this.form.pics.businessLicenseCopy = res.url
          } else if (type === 'idCardCopy') {
            this.form.idCardInfo.idCardCopy = res.wxCode
            this.form.pics.idCardCopy = res.url
          } else if (type === 'idCardNational') {
            this.form.idCardInfo.idCardNational = res.wxCode
            this.form.pics.idCardNational = res.url
          } else if (type === 'idDocCopy') {
            this.form.idDocInfo.idDocCopy = res.wxCode
            this.form.pics.idDocCopy = res.url
          }
        })
        .catch(err => {
          console.log('>>>>>>> err', err)
          this.$message.error(err.msg || '上传失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    handleBusinessLicenseCopy(res) {
      return this.handleUploadWxFile(res.raw, 'businessLicenseCopy')
    },
    handleIdCardCopy(res) {
      return this.handleUploadWxFile(res.raw, 'idCardCopy')
    },
    handleIdDocCopy(res) {
      return this.handleUploadWxFile(res.raw, 'idDocCopy')
    },
    handleIdCardNational(res) {
      return this.handleUploadWxFile(res.raw, 'idCardNational')
    },
    handleBusinessAdditionPic({ raw }) {
      const loading = this.$loading()
      this.$store
        .dispatch('upload/uploadWxFile', raw)
        .then(res => {
          this.form.pics.businessAdditionPicIdList.push(res.wxCode)
          this.form.pics.businessAdditionPicList.push({ url: res.url, name: res.url })
        })
        .catch(err => {
          console.log('>>>>>>> err', err)
          this.$message.error(err.msg || '上传失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    handleBusinessAdditionPicRemove(file) {
      const n = this.form.pics.businessAdditionPicList.findIndex(i => i.url === file.url)
      this.form.pics.businessAdditionPicList.splice(n, 1)
      this.form.pics.businessAdditionPicIdList.splice(n, 1)
    },
    handleQualificationsPic({ raw }) {
      const loading = this.$loading()
      this.$store
        .dispatch('upload/uploadWxFile', raw)
        .then(res => {
          this.form.pics.qualificationIdList.push(res.wxCode)
          this.form.pics.qualificationList.push({ url: res.url, name: res.url })
        })
        .catch(err => {
          console.log('>>>>>>> err', err)
          this.$message.error(err.msg || '上传失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    handleQualificationsPicRemove(file) {
      const n = this.form.pics.qualificationList.findIndex(i => i.url === file.url)
      this.form.pics.qualificationList.splice(n, 1)
      this.form.pics.qualificationIdList.splice(n, 1)
    },
    handleSubmit() {
      if (this.form.contactInfo.mobilePhone && this.form.contactInfo.mobilePhone.length !== 11) {
        this.$message.error('手机号位数不够')
      }
      let payload = { ...this.form }

      payload.salesSceneInfo.storeName = this.form.accountName
      payload.merchantShortname = this.form.accountName
      payload.salesSceneInfo.storeUrl = `https://miniapp.egg.tanchi.shop/business?id=${this.$store.getters['vendor/params/getVendorId']}`
      // 处理补充材料数据
      if (this.form.pics.businessAdditionPicList.length) {
        payload.businessAdditionPics = JSON.stringify(this.form.pics.businessAdditionPicIdList)
        payload.pics.businessAdditionPics = this.form.pics.businessAdditionPicList.map(i => i.url).join(',')
      } else {
        delete payload.businessAdditionPics
        delete payload.pics.businessAdditionPics
      }
      // 处理特殊资质数据
      if (['2', '4'].includes(this.form.organizationType) && this.form.pics.qualificationList.length) {
        payload.qualifications = JSON.stringify(this.form.pics.qualificationIdList)
        payload.pics.qualifications = this.form.pics.qualificationList.map(i => i.url).join(',')
      } else {
        delete payload.qualifications
        delete payload.pics.qualifications
      }
      if (this.form.contactInfo.contactType == 65) {
        payload.contactInfo.contactName = this.contactInfo.name
        payload.contactInfo.contactIdCardNumber = this.contactInfo.number
      }
      if (this.form.accountInfo.bankAccountType === '75') {
        if (this.form.idDocType === 'IDENTIFICATION_TYPE_MAINLAND_IDCARD') {
          payload.accountInfo.accountName = this.form.idCardInfo.idCardName
        } else {
          payload.accountInfo.accountName = this.form.idDocInfo.idCardName
        }
      }
      if (this.form.idDocType === 'IDENTIFICATION_TYPE_MAINLAND_IDCARD') {
        delete payload.idDocInfo
      } else {
        delete payload.idCardInfo
      }
      this.$refs['form'].validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return true
        } else {
          if (this.$store.getters['vendor/params/getAccountId'] !== '0') {
            if (this.isLockBasicInfo) {
              this.handleUpdateBankInfo(payload)
            } else {
              this.handleUpdateInfo(payload)
            }
          } else {
            // if (!this.form.wxOpenId) {
            //   this.$message.error('请该账号用户登录到贪吃Pro完成注册')
            //   return
            // }

            if (
              payload.organizationType == 4 &&
              payload.accountInfo.bankAccountType == 75 &&
              payload.idDocType !== 'IDENTIFICATION_TYPE_MAINLAND_IDCARD'
            ) {
              payload.accountInfo.accountName = payload.idDocInfo.idDocName
            }

            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading'
            })
            this.$http
              .post('/boom-center-product-service/sysAdmin/subMerchant/add', payload)
              .then(res => {
                this.form.accountName = ''
                this.$message.success(`添加成功`)

                this.$router.push({
                  path: `/vendor/vendorMange/account`
                })
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
              .finally(() => {
                loading.close()
              })
          }
        }
      })
    },
    handleUpdateBankInfo(payload) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })
      let params = {}

      if (payload.accountInfo.bankName && payload.accountInfo.accountBank === '其他银行') {
        params.bankName = payload.accountInfo.bankName
      }
      this.$http
        .put('/boom-center-product-service/sysAdmin/subMerchant/settlement', {
          outRequestNo: this.outRequestNo,
          accountBank: payload.accountInfo.accountBank,
          bankAccountType: payload.accountInfo.bankAccountType,
          bankAddressCode: payload.accountInfo.bankAddressCode,
          accountNumber: payload.accountInfo.accountNumber,

          ...params
        })

        .then(res => {
          this.$message.success(`修改${this.form.accountName}成功`)
          this.form.accountName = ''

          this.$router.push({
            path: `/vendor/vendorMange/account`
          })
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    handleUpdateInfo(payload) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })
      this.$http
        .put('/boom-center-product-service/sysAdmin/subMerchant', {
          ...payload
        })
        .then(res => {
          this.$message.success(`修改${this.form.accountName}成功`)
          this.form.accountName = ''

          this.$router.push({
            path: `/vendor/vendorMange/account`
          })
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    getDetail(id) {
      this.$http
        .get(`/boom-center-product-service/sysAdmin/subMerchant/${id}`)
        .then(async res => {
          this.form = {
            ...this.form,
            ...res,
            businessLicenseInfo: res.businessLicenseInfo || this.form.businessLicenseInfo
          }

          if (!this.form.accountInfo) {
            this.form.accountInfo = {
              accountBank: '',
              accountName: '',
              accountNumber: '',
              bankAccountType: '75',
              bankAddressCode: '',
              bankBranchId: '',
              bankName: '',
              cityCode: ''
            }
          }
          this.originCityCodeList.forEach(item => {
            item.child.forEach(childItem => {
              if (this.form.accountInfo && this.form.accountInfo.bankAddressCode == childItem.id) {
                this.bankAddressCode = [childItem.parentId, childItem.id]
              }
            })
          })
          // this.onPhoneChange(this.form.contactInfo.mobilePhone)
          if (res.businessAdditionPics && res.businessAdditionPics.length) {
            this.form.pics.businessAdditionPicList = (
              await Promise.all(
                res.pics.businessAdditionPics
                  .split(',')
                  .filter(i => i)
                  .map(i => this.$store.dispatch('upload/getFileUrlWidthSignature', i))
              )
            ).map(i => ({
              url: i,
              name: i
            }))
            this.form.pics.businessAdditionPicIdList = JSON.parse(res.businessAdditionPics)
          }
          if (res.qualifications && res.qualifications.length) {
            this.form.pics.qualificationList = (
              await Promise.all(
                res.pics.qualifications
                  .split(',')
                  .filter(i => i)
                  .map(i => this.$store.dispatch('upload/getFileUrlWidthSignature', i))
              )
            ).map(i => ({
              url: i,
              name: i
            }))
            this.form.pics.qualificationIdList = JSON.parse(res.qualifications)
          }
          if (res.applymentState == 4) {
            this.getAuditDetail(res.outRequestNo)
          }
          if (res.applymentState == 6) {
            this.isLockBasicInfo = true
          }

          this.form.needAccountInfo = false
          if (res.accountInfo && res.accountInfo.accountNumber) {
            this.form.needAccountInfo = true
          }
          this.outRequestNo = res.outRequestNo
          if (this.form.pics.idCardCopy) {
            this.form.pics.idCardCopy = await this.$store.dispatch(
              'upload/getFileUrlWidthSignature',
              this.form.pics.idCardCopy
            )
          }
          if (this.form.pics.idCardNational) {
            this.form.pics.idCardNational = await this.$store.dispatch(
              'upload/getFileUrlWidthSignature',
              this.form.pics.idCardNational
            )
          }
          if (this.form.pics.businessLicenseCopy) {
            this.form.pics.businessLicenseCopy = await this.$store.dispatch(
              'upload/getFileUrlWidthSignature',
              this.form.pics.businessLicenseCopy
            )
          }
          if (this.form.pics.idDocCopy) {
            this.form.pics.idDocCopy = await this.$store.dispatch(
              'upload/getFileUrlWidthSignature',
              this.form.pics.idDocCopy
            )
          }
          if (!this.form.pics.businessAdditionPicList) {
            this.form.pics.businessAdditionPicList = []
          }
          if (!this.form.pics.businessAdditionPicIdList) {
            this.form.pics.businessAdditionPicIdList = []
          }
          if (!this.form.pics.qualificationList) {
            this.form.pics.qualificationList = []
          }
          if (!this.form.pics.qualificationIdList) {
            this.form.pics.qualificationIdList = []
          }
          this.handleOrganizationTypeChange(res.organizationType)
        })
        .catch(err => {
          this.$message.error(err.msg || err)
        })
    },
    getAuditDetail(outRequestNo) {
      this.$http.get(`/boom-center-product-service/sysAdmin/subMerchant/out-request-no/${outRequestNo}`).then(res => {
        this.auditDetail = res.auditDetail
      })
    },
    async getCityCodeList() {
      this.originCityCodeList = await this.$http.get('/boom-center-product-service/sysAdmin/provinceCityDistrict/all')
    }
  },
  created() {
    this.form.businessId = this.$store.getters['vendor/params/getVendorId']

    if (!this.$store.getters['vendor/params/getVendorId']) {
      this.$router.push('/malls/overview')
    }
    this.setRules()
  },
  async mounted() {
    await this.getCityCodeList()
    if (this.$store.getters['vendor/params/getAccountId'] !== '0') {
      this.getDetail(this.$store.getters['vendor/params/getAccountId'])
    } else {
      if (this.$store.getters['cache/editAccountData']) {
        this.$log('从缓存中恢复数据', this.$store.getters['cache/editAccountData'])
        this.form = {
          ...this.$store.getters['cache/editAccountData'],
          businessId: this.$store.getters['vendor/params/getVendorId']
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.form.accountName && this.$store.getters['vendor/params/getAccountId'] === '0') {
      this.$confirm('是否需要缓存当前未提交的内容?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.commit('cache/add', {
            key: 'editAccountData',
            value: this.form
          })
        })
        .catch(() => {
          this.$store.commit('cache/clear', 'editAccountData')
        })
        .finally(() => {
          this.$destroy()
          next()
        })
    } else {
      this.$destroy()
      next()
    }
  }
}
</script>

<style lang="less" scoped>
.accountContainer {
  color: @color-primary;
  margin: @container-margin;
  .vendorFrom {
    margin-left: 20px;
    .idImage {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin-right: 20px;
    }
    .el-input {
      width: 400px;
    }
    .title {
      margin: 20px 0;
    }
    .assist {
      .assistText;
    }
  }
}
.auditDetailContainer {
  margin: @container-margin;
  background: #fdd4cd;
  color: #ae1800;
}
.wxInfoContainer {
  display: flex;
  align-items: center;
  .avatar {
    .rounded(40px);
    margin: 0 15px;
  }
}
</style>
